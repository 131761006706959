// src/components/Special/ReservationStepOne.tsx

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import reservationStore from '../../../stores/ReservationsStore';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { enUS } from 'date-fns/locale/en-US'; // Adjusted import
import { format, parse } from 'date-fns';

registerLocale('en-US', enUS);

const ReservationStepOne: React.FC = observer(() => {
  const [guestError, setGuestError] = useState('');

  const handleNext = (e: React.FormEvent) => {
    e.preventDefault();
    const { date, hour, minute, guests, venue } = reservationStore.formData;

    // Basic validation
    if (!date || !hour || !minute || !guests || !venue) {
      toast.error('Please fill out all required fields.');
      return;
    }

    // Check number of guests
    if (guests < 5) {
      setGuestError(
        'For parties of fewer than 5 guests, please use the white reservations button in the header to book your reservation immediately.'
      );
      return;
    } else {
      setGuestError('');
    }

    reservationStore.nextStep();
  };

  // Handle Date Change with DatePicker
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      reservationStore.setDate(formattedDate);
    }
  };

  // Generate Hour Options
  const generateHourOptions = (): string[] => {
    const hours: string[] = [];
    const startHour = 16; // 4 PM
    const endHour = 21; // 11 PM

    for (let hour = startHour; hour <= endHour; hour++) {
      const formattedHour =
        hour > 12 ? `${hour - 12} PM` : hour === 12 ? `${hour} PM` : `${hour === 0 ? 12 : hour} AM`;
      hours.push(formattedHour);
    }
    return hours;
  };

  // Generate Minute Options
  const generateMinuteOptions = (): string[] => {
    return ['00', '30'];
  };

  const hourOptions: string[] = generateHourOptions();
  const minuteOptions: string[] = generateMinuteOptions();

  // Prepare selected date for DatePicker
  const selectedDate = reservationStore.formData.date
    ? parse(reservationStore.formData.date, 'yyyy-MM-dd', new Date())
    : null;

  return (
    <form onSubmit={handleNext} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
        {/* Date */}
        <div className="flex flex-col">
          <label htmlFor="date" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Date<span className="text-holdrens-gold">*</span>
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black cursor-pointer"
            placeholderText="Select a date"
            locale="en-US"
            wrapperClassName="w-full"
            popperPlacement="bottom-start"
            required
            popperContainer={(props) => <div style={{ zIndex: 8888 }}>{props.children}</div>}
            withPortal // Renders date picker in a portal
          />
        </div>

        {/* Time */}
        <div className="flex flex-col">
          <label htmlFor="time" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Time<span className="text-holdrens-gold">*</span>
          </label>
          <div className="flex space-x-2">
            {/* Hour Selector */}
            <select
              id="hour"
              name="hour"
              className="w-1/2 px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black cursor-pointer"
              value={reservationStore.formData.hour}
              onChange={(e) => reservationStore.setHour(e.target.value)}
              required
            >
              <option value="">Hour</option>
              {hourOptions.map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>

            {/* Minute Selector */}
            <select
              id="minute"
              name="minute"
              className="w-1/2 px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black cursor-pointer"
              value={reservationStore.formData.minute}
              onChange={(e) => reservationStore.setMinute(e.target.value)}
              required
            >
              <option value="">Minute</option>
              {minuteOptions.map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Number of Guests */}
        <div className="flex flex-col">
          <label htmlFor="guests" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Number of Guests<span className="text-holdrens-gold">*</span>
          </label>
          <input
            type="number"
            id="guests"
            name="guests"
            min="1"
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black"
            value={reservationStore.formData.guests}
            onChange={(e) => reservationStore.setGuests(Number(e.target.value))}
            required
          />
          {guestError && <p className="text-xs text-holdrens-red mt-1">{guestError}</p>}
        </div>

        {/* Venue Choice */}
        <div className="flex flex-col">
          <label htmlFor="venue" className="block text-sm font-paperboard mb-1 text-holdrens-red">
            Venue Choice<span className="text-holdrens-gold">*</span>
          </label>
          <select
            id="venue"
            name="venue"
            className="w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-holdrens-gold text-black cursor-pointer"
            value={reservationStore.formData.venue}
            onChange={(e) => reservationStore.setVenue(e.target.value as 'Private Patio' | 'Red Room')}
            required
          >
            <option value="">Select Venue</option>
            <option value="Private Patio">Private Patio (Seats up to 40)</option>
            <option value="Red Room">Red Room (Indoor, Seats up to 20)</option>
          </select>
        </div>
      </div>

      {/* Next Button */}
      <div className="text-center">
        <button
          type="submit"
          className="px-8 py-3 bg-holdrens-red text-white font-paperboard font-semibold hover:bg-holdrens-gold hover:text-black transition-colors"
        >
          Next
        </button>
      </div>
    </form>
  );
});

export default ReservationStepOne;