import { CLSMetric, FIDMetric, FCPMetric, LCPMetric, TTFBMetric } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: { (metric: CLSMetric): void; (metric: FIDMetric): void; (metric: FCPMetric): void; (metric: LCPMetric): void; (metric: TTFBMetric): void; } | undefined) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;